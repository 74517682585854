import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Rize',
    useCases: 'BRANDING, UX, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION',
    description: `RIZE was a startup that empowered social media influencers to leverage the power of their audience by developing their own brand.
    Longer description coming soon.`,
    comment: 'usecase coming soon',
  },
  caseId: 'rize',
  contents: ['image0.jpg'],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
